import posthog from "posthog-js";

// initialise Posthog for tracking
// Filtering is handled on Posthog, so these details can be the same.
posthog.init("phc_s7YEjvuQgwHXvu8JWSWoRCxrVTt4oS7q9JwnJxJsEzA", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only",
  session_recording: {
    maskAllInputs: true,
  },
});

(window as any).Posthog = posthog;
export default posthog;
